<template>
  <div class="ls-page">
    <div class="ps-section__header ls-page-header">
      <h2 class="container ls-page-header-title">Prescriptions Requests</h2>
      <h3 class="container ls-page-header-subtitle">
        Pay for any prescription in real time so that family can pick up their
        medications without delay
      </h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="ps-section__right">
            <div class="ps-section--account-setting">
              <div class="ps-section__content">
                <div class="d-sm-flex align-items-center justify-content-between mb-4 mt-4">
                  <h3>Prescriptions Requests</h3>
                  <div class="align-items-left">
                    <router-link :to="{ name: 'create_prescription_request' }" class="ps-btn btn-sm m-3"
                      v-if="auth.isAuthenticated"><i class="icon-upload"></i> Upload Order
                    </router-link>
                    <router-link :to="{ name: 'login' }" class="ps-btn btn-sm m-3" v-else><i class="icon-upload"></i> Upload
                      Order
                    </router-link>
                    &nbsp;&nbsp;
                    <router-link :to="{ name: 'my_prescriptions' }" class="ps-btn btn-sm m-3" v-if="auth.isAuthenticated"><i
                        class="icon-list"></i> Pending Orders
                    </router-link>
                    <router-link :to="{ name: 'login' }" class="ps-btn btn-sm m-3" v-else><i class="icon-list"></i> Pending
                      Orders
                    </router-link>
                    &nbsp;&nbsp;
                    <router-link :to="{ name: 'my_prescriptions' }" class="ps-btn btn-sm m-3" v-if="auth.isAuthenticated"><i
                        class="icon-list"></i> Done Orders
                    </router-link>
                    <router-link :to="{ name: 'login' }" class="ps-btn btn-sm m-3" v-else><i class="icon-list"></i> Done
                      Orders
                    </router-link>
                  </div>
                </div>

                <div class="table-responsive">
                  <div class="table-responsive">
                    <table class="table ps-table ps-table--vendor">
                      <thead>
                        <tr>
                          <th>Reference</th>
                          <th>Created At</th>
                          <th>Notes</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="prescription in prescriptions" :key="prescription.id">
                          <td>{{ prescription.id }}</td>
                          <td>
                            <a href="#">{{ prescription.created_at }}</a>
                          </td>
                          <td>
                            <a href="#">{{ prescription.notes }}</a>
                          </td>
                          <td>
                            <router-link :to="{
                              name: 'merchant_edit_prescriptions',
                              params: { id: prescription.id },
                            }">
                              <i class="fa fa-edit"></i>
                            </router-link>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr v-if="(!meta || meta.total === 0) && !isLoading">
                          <td colspan="5" class="text-center m-4 text-danger">
                            No prescriptions requests available
                          </td>
                        </tr>
                        <tr v-else-if="isLoading">
                          <td colspan="5" class="text-center m-4 text-danger">
                            Loading prescription requests...
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "ListPrescriptionMedication",
  data() {
    return {
      orders: [],
      meta: null,
      isLoading: false,
      isDeleting: false,
    };
  },
  mounted() {
    this.getOrders();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
    }),
  },
  methods: {
    getOrders() {
      this.isLoading = true;
      axios
        .get("medicalprescriptions")
        .then((response) => {
          this.isLoading = false;
          this.prescriptions = response.data.data;
          this.meta = response.data.meta;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error.response);
        });
    },
    deleteOrder(id) {
      this.loading = true;
      axios
        .delete("medicalprescriptions" + id)
        .then((response) => {
          console.log(response.data);
          this.getOrders();
          if (response.status === 200 || response.status === 201) {
            location.reload();
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
